var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isEditLiveSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Editar Podcast ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('img',{staticStyle:{"max-width":"150px"},attrs:{"src":_vm.form.imgOriginal}}),_c('validation-provider',{attrs:{"name":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Capa ","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","placeholder":"Selecione a capa...","drop-placeholder":"Arraste seu arquivo para aqui..."},model:{value:(_vm.img),callback:function ($$v) {_vm.img=$$v},expression:"img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Titulo","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo do link","label-for":"type"}},[_c('v-select',{attrs:{"id":"type","options":_vm.type_link,"label":"Tipo","placeholder":"Selecione a opção","state":errors.length > 0 ? false : null},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"url","label-for":"url"}},[_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false : null},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", $$v)},expression:"form.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"apple_podcast"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"apple_podcast","label-for":"apple_podcast"}},[_c('b-form-input',{attrs:{"id":"url","state":errors.length > 0 ? false : null},model:{value:(_vm.form.apple_podcast),callback:function ($$v) {_vm.$set(_vm.form, "apple_podcast", $$v)},expression:"form.apple_podcast"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"castro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"castro","label-for":"castro"}},[_c('b-form-input',{attrs:{"id":"castro","state":errors.length > 0 ? false : null},model:{value:(_vm.form.castro),callback:function ($$v) {_vm.$set(_vm.form, "castro", $$v)},expression:"form.castro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"deezer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"deezer","label-for":"deezer"}},[_c('b-form-input',{attrs:{"id":"deezer","state":errors.length > 0 ? false : null},model:{value:(_vm.form.deezer),callback:function ($$v) {_vm.$set(_vm.form, "deezer", $$v)},expression:"form.deezer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"doogle_podcast"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"doogle_podcast","label-for":"doogle_podcast"}},[_c('b-form-input',{attrs:{"id":"doogle_podcast","state":errors.length > 0 ? false : null},model:{value:(_vm.form.doogle_podcast),callback:function ($$v) {_vm.$set(_vm.form, "doogle_podcast", $$v)},expression:"form.doogle_podcast"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"overcast"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"overcast","label-for":"overcast"}},[_c('b-form-input',{attrs:{"id":"overcast","state":errors.length > 0 ? false : null},model:{value:(_vm.form.overcast),callback:function ($$v) {_vm.$set(_vm.form, "overcast", $$v)},expression:"form.overcast"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"pca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"pca","label-for":"pca"}},[_c('b-form-input',{attrs:{"id":"pca","state":errors.length > 0 ? false : null},model:{value:(_vm.form.pca),callback:function ($$v) {_vm.$set(_vm.form, "pca", $$v)},expression:"form.pca"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"soundcloud"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"soundcloud","label-for":"soundcloud"}},[_c('b-form-input',{attrs:{"id":"soundcloud","state":errors.length > 0 ? false : null},model:{value:(_vm.form.soundcloud),callback:function ($$v) {_vm.$set(_vm.form, "soundcloud", $$v)},expression:"form.soundcloud"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"spotify"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"spotify","label-for":"spotify"}},[_c('b-form-input',{attrs:{"id":"spotify","state":errors.length > 0 ? false : null},model:{value:(_vm.form.spotify),callback:function ($$v) {_vm.$set(_vm.form, "spotify", $$v)},expression:"form.spotify"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"youtube"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"youtube","label-for":"youtube"}},[_c('b-form-input',{attrs:{"id":"youtube","state":errors.length > 0 ? false : null},model:{value:(_vm.form.youtube),callback:function ($$v) {_vm.$set(_vm.form, "youtube", $$v)},expression:"form.youtube"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data","label-for":"date"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"date"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"start_time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Inicio","label-for":"start_time"}},[_c('b-form-timepicker',{attrs:{"locale":"pt-br"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"crm_required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CRM OBRIGATORIO","label-for":"crm_required"}},[_c('v-select',{attrs:{"id":"crm_required ","options":_vm.boolean,"label":"Tipo","placeholder":"Selecione a opção","state":errors.length > 0 ? false : null},model:{value:(_vm.form.crm_required),callback:function ($$v) {_vm.$set(_vm.form, "crm_required", $$v)},expression:"form.crm_required"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ready","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pronto para iniciar","label-for":"ready"}},[_c('v-select',{attrs:{"id":"ready ","options":_vm.boolean,"label":"Tipo","placeholder":"Selecione a opção","state":errors.length > 0 ? false : null},model:{value:(_vm.form.ready),callback:function ($$v) {_vm.$set(_vm.form, "ready", $$v)},expression:"form.ready"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Atualizar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancelar ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }