// eslint-disable-next-line import/no-unresolved
import axios from '@axios'

export default function LivesServices() {
  const getLives = async (queryParams) => {
    const api = await axios.get('/podcasts', queryParams)
    return api
  }

  const getLiveFrame = async (queryParams) => {
    const api = await axios.get('/lives/frame/id', {
      params: queryParams,
    })
    return api
  }

  const getLiveSpeakerBySpeaker = async (queryParams) => {
    const api = await axios.get('/live/speaker/byspeaker', {
      params: queryParams,
    })
    console.log(api)
    return api
  }

  const getLiveSpeakerByLive = async (queryParams) => {
    const api = await axios.get('/live/speaker/bylive', {
      params: queryParams,
    })

    return api
  }

  const liveMonitoring = async (queryParams) => {
    const api = await axios.post('/podcast/monitoring', queryParams)
    return api
  }

  const liveCreate = async (queryParams) => {
    const api = await axios.post('/podcast/create', queryParams)
    console.log(api)
    return api
  }

  const liveDelete = async (queryParams) => {
    const api = await axios.post('/podcast/delete', queryParams)
    console.log(api)
    return api
  }

  const liveEdit = async (queryParams) => {
    const api = await axios.post('/podcast/update', queryParams)
    console.log(api)
    return api
  }

  return {
    getLives,
    getLiveFrame,
    getLiveSpeakerBySpeaker,
    getLiveSpeakerByLive,
    liveMonitoring,
    liveCreate,
    liveDelete,
    liveEdit,
  }
}
